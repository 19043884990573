<template>
  <v-card
    v-if="!isNullRow"
    elevation="5"
    class="my-2 mx-2"
    @click="rowClicked($event, item)"
  >
    <v-card-title class="subheading font-weight-bold"
      ><template v-if="isMobile">
        {{ item.name.slice(0, 8) }} - {{ item.operationnelName }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="isExpanded"
          fab
          dark
          small
          @click.prevent="removeFromExtand(item)"
        >
          <v-icon :style="{ color: getStatusColor(item.operationnel) }"
            >mdi-chevron-up</v-icon
          >
        </v-btn>
        <v-btn
          v-else
          fab
          small
          :style="{ 'background-color': getStatusColor(item.operationnel) }"
          @click.prevent="expandRow(item)"
          ><v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-else
        >{{ item.name }} - {{ item.operationnelName }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="isExpanded"
          fab
          dark
          small
          @click.prevent="removeFromExtand(item)"
        >
          <v-icon :style="{ color: getStatusColor(item.operationnel) }"
            >mdi-chevron-up</v-icon
          >
        </v-btn>
        <v-btn
          v-else
          fab
          small
          :style="{ 'background-color': getStatusColor(item.operationnel) }"
          @click.prevent="expandRow(item)"
          ><v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
    </v-card-title>

    <v-divider></v-divider>

    <v-list dense v-if="isExpanded">
      <v-list-item v-for="header in headers" :key="header.value">
        <v-list-item-content>{{ header.text }}:</v-list-item-content>
        <v-list-item-content class="align-end">
          <slot :name="header.value" :item="item" :header="header">
            {{ item[header.value] }}
          </slot>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { getObjectValueByPath, statusListEquipment } from "../utils";

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isNullRow() {
      return !getObjectValueByPath(this.item, this.headers[0].value);
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 400;
    }
  },
  methods: {
    expandRow(row) {
      this.$emit("expand", row.id);
    },
    removeFromExtand(row) {
      this.$emit("n-expand", row.id);
    },
    rowClicked(e, item) {
      if (e.target.tagName === "DIV") this.$emit("row-clicked", item);
    },
    getStatusColor(status) {
      return status
        ? statusListEquipment.find(el => el.status === status).color
        : "";
    }
  }
};
</script>
