<template>
  <v-container class="fill-height" fluid>
    <template v-if="isKpi">
      <v-card v-if="isMobile" flat width="100%">
        <v-row>
          <v-card width="100%" class="elevation-2 ma-1">
            <v-col>
              <apexchart
                height="300"
                type="bar"
                :options="chartOptions1"
                :series="series1"
                :key="refreshKey"
              ></apexchart>
            </v-col>
          </v-card>
        </v-row>
        <v-row>
          <v-card width="100%" class="elevation-2 ma-1">
            <v-col>
              <apexchart
                height="300"
                type="bubble"
                :options="chartOptions5"
                :series="series5"
                :key="refreshKey"
              ></apexchart>
              <!--
              <apexchart
                height="300"
                type="radialBar"
                :options="chartOptions2"
                :series="series2"
                :key="refreshKey"
              ></apexchart>-->
            </v-col>
          </v-card>
        </v-row>
        <v-row>
          <v-card width="100%" class="elevation-2 ma-1">
            <v-row>
              <v-col cols="10">
                <apexchart
                  height="300"
                  type="donut"
                  :options="chartOptions3"
                  :series="series3"
                  :key="refreshKey"
                ></apexchart>
              </v-col>
              <v-col cols="2">
                <v-card
                  color="#00AFD7"
                  class="subtitle-2 pl-1 pa-md-1 mt-10 ml-n6 white--text"
                  >Nouveau
                </v-card>
                <v-card
                  color="#0072CE"
                  class="subtitle-2 pl-1 pa-md-1 mt-10 ml-n6 white--text"
                  >Attribué
                </v-card>
                <v-card
                  color="#00965E"
                  class="subtitle-2 pl-1 pa-md-1 mt-10 ml-n6 white--text"
                  >Planifié
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row>
          <v-card width="100%" class="elevation-2 ma-1">
            <v-col cols="12">
              <apexchart
                height="288"
                type="treemap"
                :options="chartOptions4"
                :series="series4"
                :key="refreshKey"
              ></apexchart>
            </v-col>
          </v-card>
        </v-row>
      </v-card>
      <v-card v-else flat width="100%">
        <v-row>
          <v-card width="49%" class="elevation-2 ma-1">
            <v-col>
              <apexchart
                :height="hauteur"
                type="bar"
                :options="chartOptions1"
                :series="series1"
                :key="refreshKey"
              ></apexchart>
            </v-col>
          </v-card>
          <v-card width="49%" class="elevation-2 ma-1">
            <v-col>
              <apexchart
                :height="hauteur"
                type="bubble"
                :options="chartOptions5"
                :series="series5"
                :key="refreshKey"
              ></apexchart>
              <!--
              <apexchart
                height="300"
                type="radialBar"
                :options="chartOptions2"
                :series="series2"
                :key="refreshKey"
              ></apexchart>-->
            </v-col>
          </v-card>
        </v-row>
        <v-row>
          <v-card width="49%" class="elevation-2 ma-1">
            <v-row>
              <v-col cols="10">
                <apexchart
                  height="300"
                  type="donut"
                  :options="chartOptions3"
                  :series="series3"
                  :key="refreshKey"
                ></apexchart>
              </v-col>
              <v-col cols="2">
                <v-card
                  color="#00AFD7"
                  class="subtitle-2 pl-1 pa-md-1 mt-10 ml-n6 white--text"
                  >Nouveau
                </v-card>
                <v-card
                  color="#0072CE"
                  class="subtitle-2 pl-1 pa-md-1 mt-10 ml-n6 white--text"
                  >Attribué
                </v-card>
                <v-card
                  color="#00965E"
                  class="subtitle-2 pl-1 pa-md-1 mt-10 ml-n6 white--text"
                  >Planifié
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <v-card width="49%" class="elevation-2 ma-1">
            <v-col cols="12">
              <apexchart
                height="288"
                type="treemap"
                :options="chartOptions4"
                :series="series4"
                :key="refreshKey"
              ></apexchart>
            </v-col>
          </v-card>
        </v-row>
      </v-card>
      <!---
      <div id="chart">
        <apexchart
          type="bar"
          height="350"
          width="750"
          :options="chartOptions3"
          :series="series3"
        ></apexchart>
      </div>
      <div>
        <apexchart
          width="500"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div id="chart">
        <apexchart
          ref="realtimeChart"
          width="500"
          type="bar"
          :options="chartOptions5"
          :series="series5"
        ></apexchart>
      </div>-->
      <v-row class="mt-n3">
        <v-col></v-col><v-spacer></v-spacer>
        <v-col>
          <v-card-text>Dernier login : {{ lastUnix }}</v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            Mise à jour le {{ majUnix }}
            <v-btn small @click="reLoad" color="primary" title="Actualiser">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="isMobile">
        <v-card width="100%">
          <v-carousel
            cycle
            height="330"
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="slide in documentsImages"
              :key="slide.id"
              :src="slide.image"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </template>
      <template v-else>
        <v-row class="mb-6" no-gutters>
          <v-col md="10" offset-md="1">
            <v-card width="100%">
              <v-carousel
                cycle
                height="330"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="slide in documentsImages"
                  :key="slide.id"
                  :src="slide.image"
                ></v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center" class="mt-4" no-gutters>
        <v-col cols="12">
          <v-card>
            <v-card-title class="headline display-1">
              {{ $t("asset.list_assets") }}
            </v-card-title>
            <v-data-table
              locale="fr-FR"
              :headers="filteredHeader"
              :items="filteredItems"
              @click:row="clicked"
              item-key="id"
              :loading="loading"
              class="elevation-1 list__asset"
              id="assetsTable2"
              :page.sync="page"
              sort-by="tickets"
              sort-desc
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item="{ item }">
                <c-row
                  :item="item"
                  :headers="filteredHeader"
                  rtl
                  :class="{
                    'asset-level': item.level === 3 || item.level === 4
                  }"
                  ignore-null-val
                  @click="clicked(item)"
                >
                  <template v-slot:tickets="{ item }">
                    {{ item.tickets === 0 ? "-" : item.tickets }}
                  </template>
                  <template v-slot:technique="{ item }">
                    {{ item.technique === 0 ? "-" : item.technique }}
                  </template>
                  <template v-slot:permanent="{ item }">
                    {{ item.permanent === 0 ? "-" : item.permanent }}
                  </template>
                  <template v-if="isMobile" v-slot:etageportefield="{ item }">
                    {{
                      item.level === 3 || item.level === 4
                        ? `${item.town ? item.town : ""} (${
                            item.postcode ? formatCP(item.postcode) : ir
                          })`
                        : item.etageportefield
                    }}
                  </template>
                  <template v-else v-slot:etageportefield="{ item }">
                    {{
                      item.level === 3 || item.level === 4
                        ? `${item.address ? item.address : ""} ${
                            item.postcode ? item.postcode : ""
                          } ${item.town ? item.town : ""}`
                        : item.etageportefield
                    }}
                  </template>
                  <template v-slot:eventsnoresa="{ item }">
                    <div
                      class="incident description"
                      v-if="item.eventsnoresa"
                      v-html="
                        item.eventsnoresa.split('<div></div><br/>').join('')
                      "
                    ></div>
                  </template>
                </c-row>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"> </v-pagination>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadStates, permissions, formatCP, sumBy, flatten } from "../utils";
import { UserSessionData } from "../mixins/userData";
import Row from "../components/Row";
import { PORTAL_CONFIG } from "../config";
import moment from "../plugins/moment";
import Swal from "sweetalert2";
import { mySwalClass } from "../utils/alert";
import USER_API from "../api/user";

const header = {};

export default {
  name: "Lgtsassets",
  components: { [Row.name]: Row },
  data() {
    return {
      refreshKey: 1,
      treeData: [],
      page: 1,
      majUnix: "",
      pageCount: 0,
      itemsPerPage: 30,
      items: [{ ...header }],
      series5: [],
      chartOptions5: {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.zoomDataSejour(event, chartContext, config);
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val > 1 ? val : "";
          }
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter: function(value, opt) {
              //let menage = opt.w.config.series[opt.seriesIndex].data[0][1]
              //let eqpt = opt.w.config.series[opt.seriesIndex].data[0][0]
              //let sejour = opt.w.config.series[opt.seriesIndex].data[0][2]
              let ent = opt.w.config.series[opt.seriesIndex].name;
              return ent.split("@")[1];
            }
          },
          y: {
            show: true,
            title: {
              show: true,
              formatter: () => ""
            },
            formatter: function(value, opt) {
              let menage = opt.w.config.series[opt.seriesIndex].data[0][1];
              let eqpt = opt.w.config.series[opt.seriesIndex].data[0][0];
              //let sejour = opt.w.config.series[opt.seriesIndex].data[0][2]
              //console.log(opt.w.config)
              return (
                "M : " +
                Math.trunc(menage, 2) +
                " % - E : " +
                Math.trunc(eqpt, 2) +
                " %"
              );
            }
          },
          z: {
            formatter: undefined,
            title: "Séjours en cours: "
          },
          marker: {
            show: true
          },
          fixed: {
            enabled: false,
            position: "topRight",
            offsetX: 0,
            offsetY: 0
          }
        },
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "top",
          offsetX: "",
          offsetY: "",
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opt) {
            return opt.w.globals.series.length > 8
              ? seriesName.split("@")[1].slice(0, 3)
              : seriesName == "series-2"
              ? ""
              : seriesName.split("@")[1];
          }
        },
        title: {
          text: "SATISFACTION DES SÉJOURS"
        },
        fill: {
          opacity: 0.8
        },
        xaxis: {
          title: {
            text: "Etat des équipements",
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600
            }
          },
          min: -10,
          max: 110,
          tickAmount: 12,
          type: "numeric",
          labels: {
            formatter: value => {
              if (value == 110 || value == -10) return "";
              else return value + "%";
            }
          }
        },
        yaxis: {
          title: {
            text: "Qualité du ménage",
            offsetX: 5,
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600
            }
          },
          min: -10,
          max: 110,
          tickAmount: 12,
          labels: {
            formatter: value => {
              if (value == 110 || value == -10) return "";
              else return value + "%";
            }
          }
        },
        plotOptions: {
          bubble: {
            zScaling: false,
            minBubbleRadius: 8,
            maxBubbleRadius: 40
          }
        }
      },
      series1: [],
      chartOptions1: {
        chart: {
          type: "bar",
          stacked: true,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.zoomDataLgt(event, chartContext, config);
            }
          }
        },
        colors: [""],
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: -10,
                style: {
                  fontSize: "13px",
                  fontWeight: 900
                }
              }
            }
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val >= 0 ? "" : val.split("@")[1];
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            }
          }
        },
        stroke: {
          width: [1, 1, 1, 1],
          colors: ["#fff"]
        },
        title: {
          text: "STATUTS DES LOGEMENTS"
        },
        dataLabels: {
          offsetY: 5,
          enabled: true
        }
      },
      series2: [],
      chartOptions2: {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.zoomDataSejour(event, chartContext, config);
            }
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: 30,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 15,
              size: "",
              background: "transparent",
              image: undefined
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        },
        theme: {
          palette: "palette2"
        },
        title: {
          text: "SATISFACTION DES SÉJOURS"
        },
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "left",
          offsetX: 40,
          offsetY: "",
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opts) {
            return (
              seriesName.split("@")[1].slice(0, 20) +
              ": " +
              opts.w.globals.series[opts.seriesIndex] +
              "%"
            );
          },
          itemMargin: {
            vertical: 0
          }
        }
      },
      series3: [],
      chartOptions3: {
        colors: [""],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.zoomData(event, chartContext, config);
            }
          }
        },
        labels: [""],
        dataLabels: {
          formatter: function(value, opt) {
            return [
              opt.w.globals.labels[opt.seriesIndex].split("@")[2] +
                " : " +
                opt.w.globals.series[opt.seriesIndex]
            ];
            //return [Math.round(value) + "%"];
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  label: "Total",
                  show: true
                }
              }
            }
          }
        },
        states: {
          hover: {
            filter: "none"
          }
        },
        theme: {
          palette: "palette2"
        },
        legend: {
          show: false,
          markers: {
            fillColors: [""]
          },
          formatter: function(val, opt) {
            let nb = opt.w.config.series.length;
            let nbEnt = nb / 3;
            return Math.trunc((opt.seriesIndex + 1) / nbEnt) ==
              (opt.seriesIndex + 1) / nbEnt
              ? val.split("@")[0]
              : "";
          }
        },
        tooltip: {
          y: {
            title: {
              formatter: function(val) {
                return val.split("@")[2];
              }
            }
          }
        },
        title: {
          text: "INCIDENTS"
        },
        responsive: [
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },

      series4: [
        {
          data: []
        }
      ],
      chartOptions4: {
        title: {
          text: "TOP6 / FLOP6"
        },
        /*chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.zoomData(event, chartContext, config);
            }
          }
        },*/
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold"
          },
          formatter: function(text) {
            return text;
            //return [text.split("%")[0]];
          },
          offsetX: -5
        },
        grid: {
          padding: {
            left: 15
          }
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function(val) {
              return Math.round(val);
            }
          }
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.5,
            reverseNegativeShade: true,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 50,
                  color: "#CD363A"
                },
                {
                  from: 50.001,
                  to: 100,
                  color: "#52B12C"
                }
              ]
            }
          }
        }
      }
      /* series: [
        {
          name: "réservations",
          data: [30, 40, 35, 50, 49, 60, 70, 91]
        }
      ],
      chartOptions: {
        chart: {
          id: "vuechart-example"
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      
      series3: [
        {
          name: "Connectés",
          data: [
            {
              x: "2011",
              y: 22,
              goals: [
                {
                  name: "Mandats",
                  value: 24,
                  strokeWidth: 5,
                  strokeHeight: 10,
                  strokeColor: "#775DD0"
                }
              ]
            } /*,
            {
              x: "2012",
              y: 44,
              goals: [
                {
                  name: "Mandats",
                  value: 54,
                  strokeWidth: 5,
                  strokeHeight: 10,
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "2013",
              y: 54,
              goals: [
                {
                  name: "Mandats",
                  value: 52,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: "round",
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "2014",
              y: 66,
              goals: [
                {
                  name: "Mandats",
                  value: 61,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: "round",
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "2015",
              y: 81,
              goals: [
                {
                  name: "Mandats",
                  value: 66,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: "round",
                  strokeColor: "#775DD0"
                }
              ]
            },
            {
              x: "2016",
              y: 67,
              goals: [
                {
                  name: "Mandats",
                  value: 70,
                  strokeWidth: 5,
                  strokeHeight: 10,
                  strokeColor: "#775DD0"
                }
              ]
            }
          ]
        }
      ],
      chartOptions3: {
        chart: {
          height: 350,
          width: 750,
          type: "bar"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        colors: ["#00E396"],
        dataLabels: {
          formatter: function(val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals;
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`;
            }
            return val;
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["Connecté", "Mandats"],
          markers: {
            fillColors: ["#00E396", "#775DD0"]
          }
        }
      },
      
      series5: [
        {
          name: "",
          data: [
            {
              x: "",
              y: null,
              goals: [
                {
                  name: "",
                  value: null,
                  strokeWidth: null,
                  strokeHeight: null,
                  strokeColor: ""
                }
              ]
            },
            {
              x: "",
              y: null,
              goals: [
                {
                  name: "",
                  value: null,
                  strokeWidth: null,
                  strokeHeight: null,
                  strokeColor: ""
                }
              ]
            },
            {
              x: "",
              y: null,
              goals: [
                {
                  name: "",
                  value: null,
                  strokeWidth: null,
                  strokeHeight: null,
                  strokeColor: ""
                }
              ]
            },
            {
              x: "",
              y: null,
              goals: [
                {
                  name: "",
                  value: null,
                  strokeWidth: null,
                  strokeHeight: null,
                  strokeColor: ""
                }
              ]
            }
          ]
        }
      ],
      chartOptions5: {
        chart: {
          height: 350,
          width: 750,
          type: "bar"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        colors: ["#00E396"],
        dataLabels: {
          formatter: function(val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals;
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`;
            }
            return val;
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["Connecté", "Mandats"],
          markers: {
            fillColors: ["#00E396", "#775DD0"]
          }
        }
      }
      */
    };
  },
  mixins: [UserSessionData],
  computed: {
    ...mapGetters([
      "getEntityLoadStatus",
      "getUserProfileStatus",
      "getTreeData",
      //"getCharges",
      "getAllTickets",
      "getUserProfiles",
      "getDocuments",
      "getDocumentsImages",
      "getReservations",
      "getAssets",
      "getEntity",
      "getUserData",
      "getUserId"
    ]),
    lastUnix() {
      return this.getUserData.last_login;
    },
    hauteur() {
      let hauteur = 300;
      this.isBig ? (hauteur = 800) : 300;
      return hauteur;
    },
    filteredHeader() {
      let headers = [
        {
          text: "NOM",
          align: "left",
          sortable: false,
          value: "label",
          class: "primary white--text"
        }
      ];

      if (
        this.getUserProfiles &&
        permissions.profilePermission("generique", this.getUserProfiles)
      ) {
        headers.push(
          /*{
            text: "",
            align: "center",
            sortable: false,
            value: "surfacemtwofield",
            class: "border-r-0 primary white--text"
          },
          {
            text: "INFO",
            align: "center",
            sortable: false,
            value: "nomcommercialfield",
            class: "border-l-0 primary white--text"
          },*/
          {
            text: "LOCALISATION",
            align: "left",
            sortable: false,
            value: "address",
            class: "border-l-0 primary white--text"
          }
        );
      }
      /*
      if (
        this.getUserProfiles &&
        permissions.profilePermission("engagement", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "ENGAGEMENT",
            align: "center",
            sortable: false,
            value: "datedbutdebailfield",
            class: "primary white--text"
          },
          {
            text: "DU BAIL",
            align: "left",
            sortable: false,
            value: "datefindebailfield",
            class: "border-l-0 primary white--text"
          }
        );
      }

      if (
        this.getUserProfiles &&
        permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "",
            align: "center",
            sortable: false,
            value: "loyerannuelhtfield",
            class: "primary white--text"
          },
          {
            text: "FINANCIER",
            align: "center",
            sortable: false,
            value: "charge",
            class: "border-l-0 primary white--text"
          },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "impot",
            class: "border-l-0 primary white--text"
          }
        );
      }

      headers.push(
        {
          text: "",
          align: "center",
          sortable: false,
          value: "travaux1",
          class: "primary white--text"
        },
        {
          text: "TRAVAUX",
          align: "center",
          sortable: false,
          value: "investissement",
          class: "border-l-0 primary white--text"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "travaux2",
          class: "border-l-0 primary white--text"
        }
      );
*/
      headers.push(
        {
          text: "DEGRADATIONS EN COURS",
          align: "right",
          sortable: false,
          value: "tickets",
          class: "primary white--text"
        },
        {
          text: "RÉSOLUES",
          align: "right",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text"
        },
        {
          text: "PERMANENTES",
          align: "right",
          sortable: false,
          value: "permanent",
          class: "border-l-0 primary white--text"
        },
        {
          text: "PROCHAINS EVENTS",
          align: "left",
          sortable: false,
          value: "eventsnoresa",
          class: "primary white--text"
        }
      );
      return headers;
    },
    loading() {
      return this.getEntityLoadStatus === loadStates.PENDING;
    },
    filteredItems() {
      const assetID = this.$route.params.assetID;
      let items = [];
      items.push(this.items[0]);
      if (assetID) {
        const items2 = this.items.filter(el => el.entities_id == assetID);
        items2.forEach(item => {
          const children = this.items.filter(el => el.entities_id == item.id);
          items.push(item);
          children.forEach(child => {
            items.push(child);
          });
        });
      } else {
        items = this.items;
      }
      return items.filter(
        item => item.partiescommunefield !== 1 && item.level === 4
      );
    },
    treeAssetData() {
      const firstAsset = this.getTreeData[0];
      return firstAsset.level === 2
        ? flatten(this.getTreeData.map(child => child.children))
        : this.getTreeData;
    },
    locationList() {
      const assetID = this.$route.params.assetID;
      const entities = assetID
        ? this.treeAssetData.find(el => el.id === +assetID).children
        : this.treeAssetData;
      return entities
        .filter(el => el.level === 4)
        .map(el => {
          const latlng = this.getLatLng(el);
          return {
            name: el.name,
            lat: latlng.lat,
            lng: latlng.lng,
            link: { name: "assets.show", params: { id: el.id } }
          };
        });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    isBig() {
      switch (this.getUserData.name) {
        case "carole.torta@cimalpes.com":
        case "olivier.bernard@foncia.com":
        case "stephane.querin@cimalpes.com":
          return true;
        default:
          return false;
      }
    },
    isKpi() {
      return this.getUserProfiles.includes(47);
    },
    documentsImages() {
      const assetID = this.$route.params.assetID;
      if (assetID) {
        const asset = this.treeAssetData.find(el => el.id === +assetID);
        const entitiesId = asset.children.map(entity => entity.id);
        return this.getDocumentsImages.filter(doc => {
          return (
            doc.entities_id === assetID ||
            entitiesId.includes(doc.entities_id) ||
            doc.entities_id === 1
          );
        });
      }
      return this.getDocumentsImages;
    }
  },
  methods: {
    formatCP,
    ...mapActions([
      "loadEntity",
      "loadUserProfile",
      "loadEntities",
      "killSession",
      "logout"
    ]),
    clicked: function(row) {
      if (!row.name) {
        return;
      } else if (row.level === 5) {
        this.$router.push({ name: "lots.show", params: { id: row.id } });
      } else if (row.level === 4) {
        this.$router.push({ name: "assets.show", params: { id: row.id } });
      } else {
        this.$router.push({
          name: "assets.index",
          params: { assetID: row.id }
        });
      }
    },
    getLatLng(el) {
      const latLng = el ? el.etageportefield : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    },
    getChildrenData(entity, key) {
      const filteredChild = entity.children.filter(
        child => child.partiescommunefield === 0
      );
      return sumBy(filteredChild, key);
    },
    zoomData(event, chartContext, config) {
      //console.log(config.dataPointIndex);
      //console.log(config.w.config.labels[config.dataPointIndex]);
      this.$router.push({
        name: "lots.show",
        params: {
          id: config.w.config.labels[config.dataPointIndex].split("@")[1]
        },
        query: {
          status: config.w.config.labels[config.seriesIndex].split("@")[0],
          tab: 1
        }
      });
    },
    zoomDataSejour(event, chartContext, config) {
      let decalTab = this.getUserProfiles.includes(36) ? 1 : 0;
      let decalTab2 = this.getUserProfiles.includes(41) ? 1 : 0;
      let decalTab3 = this.getUserProfiles.includes(46) ? 1 : 0;
      this.$router.push({
        name: "lots.show",
        params: {
          id: config.w.config.series[config.seriesIndex].name.split("@")[0]
        },
        query: {
          tab: 3 + decalTab + decalTab2 + decalTab3
        }
      });
    },
    zoomDataLgt(event, chartContext, config) {
      this.$router.push({
        name: "lots.show",
        params: {
          id: config.w.config.xaxis.categories[config.dataPointIndex].split(
            "@"
          )[0]
        },
        query: {
          status: config.w.config.series[config.seriesIndex].name,
          tab: 0
        }
      });
    },
    multiSort(array, sortObject = {}) {
      const sortKeys = Object.keys(sortObject);

      // Return array if no sort object is supplied.
      if (!sortKeys.length) {
        return array;
      }

      // Change the values of the sortObject keys to -1, 0, or 1.
      for (let key in sortObject) {
        sortObject[key] =
          sortObject[key] === "desc" || sortObject[key] === -1
            ? -1
            : sortObject[key] === "skip" || sortObject[key] === 0
            ? 0
            : 1;
      }

      const keySort = (a, b, direction) => {
        direction = direction !== null ? direction : 1;

        if (a === b) {
          // If the values are the same, do not switch positions.
          return 0;
        }

        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
      };

      return array.sort((a, b) => {
        let sorted = 0;
        let index = 0;

        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];

          if (key) {
            const direction = sortObject[key];

            sorted = keySort(a[key], b[key], direction);
            index++;
          }
        }
        return sorted;
      });
    },
    loadBars1() {
      let myStatusTicket = ["Nouveau", "Attribué", "Planifié"];
      let nbStatusTicket = myStatusTicket.length;
      let colorsTicket = ["#00AFD7", "#0072CE", "#00965E"];
      let myStatusLgt = ["R2R", "IN", "OUT", "NA"];
      let nbStatusLgt = myStatusLgt.length;
      let colorsLgt = ["#4CAF50", "#0072CE", "#FFC107", "#FF5252"];
      for (let j = 0; j < nbStatusLgt; j++) {
        this.series1.push({
          name: "",
          data: []
        });
      }
      let maj = 0;
      let megaTopFlop = [];
      let min = 0;
      let max = 0;
      let reduceTopFlop = [];
      let entityN4All = this.getEntity.filter(el => el.level === 4);
      let nbentityN4All = entityN4All.length;
      let entitiesN4 = "";
      for (let e = 0; e < nbentityN4All; e++) {
        let entityN4 = this.getEntity.filter(el => el.level === 4)[e];
        e < nbentityN4All - 1
          ? (entitiesN4 = entitiesN4 + entityN4.id + ",")
          : (entitiesN4 = entitiesN4 + entityN4.id);
      }
      fetch(`
        ${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/data/data.php?bdd=${PORTAL_CONFIG.APP_NAME}&entity=${entitiesN4}
      `)
        .then(response => {
          return response.json();
        })
        .then(result => {
          let nbentityN5 = 0;
          Object.keys(result).forEach(key => {
            Object.keys(result[key]).forEach(key2 => {
              if (key2 > 1) nbentityN5++;
            });
          });
          let e1 = 0;
          let i2 = 0;
          let e3 = 0;
          let i3 = 0;
          for (const [keyN4, valueN4] of Object.entries(result)) {
            if (valueN4.lastUpdate > maj) {
              maj = valueN4.lastUpdate;
              this.majUnix = moment.unix(maj).format("DD/MM/YYYY à HH:mm:ss");
            }
            for (let j = 0; j < nbStatusLgt; j++) {
              let i1 = 0;
              for (const [keyN5, valueN5] of Object.entries(valueN4)) {
                if (keyN5 > 1) {
                  if (myStatusLgt[j] == "R2R") {
                    // R2R -> somme R2R et 1
                    this.series1[j].data[i1 + e1] =
                      valueN5.state[myStatusLgt[j]] +
                      parseInt(valueN5.state.ok);
                  } else
                    this.series1[j].data[i1 + e1] =
                      valueN5.state[myStatusLgt[j]];
                  if (nbentityN4All > 1) {
                    this.chartOptions1.xaxis.categories[i1 + e1] =
                      keyN5 +
                      "@" +
                      this.getEntity.filter(el => el.id == keyN4)[0].name;
                  } else {
                    this.chartOptions1.xaxis.categories[i1 + e1] =
                      keyN5 +
                      "@" +
                      this.getEntity.filter(el => el.id == keyN5)[0].name;
                  }
                  this.series1[j].name = myStatusLgt[j];
                  this.chartOptions1.colors[j] = colorsLgt[j];
                  i1++;
                }
              }
            }
            e1 = e1 + Object.entries(valueN4).length - 1;

            let e2 = 0;
            let nbSejours = 0;
            for (const [keyN5, valueN5] of Object.entries(valueN4)) {
              if (keyN5 > 1) {
                megaTopFlop = megaTopFlop.concat(valueN5.top);
                this.series5 = this.series5.concat({
                  name: "",
                  data: []
                });
                let sat = 0;
                let sat2 = 0;
                try {
                  nbSejours = valueN5.resa["En_Cours"].nombre;
                } catch {
                  nbSejours = 0;
                }
                if (nbSejours >= 1) {
                  sat =
                    (valueN5.resa["En_Cours"].happy +
                      valueN5.resa["En_Cours"].neutral / 2) /
                    nbSejours;
                  sat2 =
                    (valueN5.resa["En_Cours"].happy2 +
                      valueN5.resa["En_Cours"].neutral2 / 2) /
                    nbSejours;
                }
                this.series5[i2 + e2].data[0] = [
                  sat2 * 100,
                  sat * 100,
                  nbSejours
                ];
                if (nbentityN4All > 1) {
                  this.series5[i2 + e2].name =
                    keyN5 +
                    "@" +
                    this.getEntity.filter(el => el.id == keyN4)[0].name;
                } else {
                  this.series5[i2 + e2].name =
                    keyN5 +
                    "@" +
                    this.getEntity.filter(el => el.id == keyN5)[0].name;
                }
                i2++;
              }
            }
            e2++;
            if (
              Object.entries(result).length + Object.entries(valueN4).length ==
              3
            ) {
              this.series5 = this.series5.concat({
                name: "",
                data: [[-50, -50, 2]]
              });
              this.chartOptions5.colors = ["", ""];
              this.chartOptions5.colors[0] = "rgb(0, 143, 251)";
              this.chartOptions5.colors[1] = "#fff";
            }

            for (let j3 = 0; j3 < nbStatusTicket; j3++) {
              i3 = 0;
              for (const [keyN5, valueN5] of Object.entries(valueN4)) {
                if (keyN5 > 1) {
                  let serieIndex = i3 + j3 * nbentityN5 + e3;
                  this.series3[serieIndex] =
                    valueN5.ticket_v2[myStatusTicket[j3]];
                  if (nbentityN4All > 1) {
                    this.chartOptions3.labels[serieIndex] =
                      myStatusTicket[j3] +
                      "@" +
                      keyN5 +
                      "@" +
                      this.getEntity.filter(el => el.id == keyN4)[0].name;
                  } else {
                    this.chartOptions3.labels[serieIndex] =
                      myStatusTicket[j3] +
                      "@" +
                      keyN5 +
                      "@" +
                      this.getEntity.filter(el => el.id == keyN5)[0].name;
                  }
                  this.chartOptions3.colors[serieIndex] = colorsTicket[j3];
                  /*if (Math.trunc(nbentityN4All / i1) != nbentityN4All / i1) {
                    this.chartOptions3.legend.markers.fillColors[serieIndex] =
                      colorsTicket[j1];
                  } else
                    this.chartOptions3.legend.markers.fillColors[serieIndex] =
                      "#fff";*/
                  i3++;
                }
              }
            }
            e3 = e3 + Object.entries(valueN4).length - 1;
          }
          let topflopSort = this.multiSort(megaTopFlop, {
            note: "desc"
          });
          let topflopSort2 = [];
          let b = 0;
          let nblgt = topflopSort.length;
          for (let a = 0; a < nblgt; a++) {
            const regex = /AAA|ZZZ|test|Test/g;
            if (!topflopSort[a].lgt.match(regex)) {
              topflopSort2[b] = topflopSort[a];
              b++;
            }
          }
          let nblgt2 = topflopSort2.length;
          let nbtopflop = 12;
          if (nblgt2 > nbtopflop) {
            let reduceTop = topflopSort2.slice(0, nbtopflop / 2);
            let reduceFlop = topflopSort2.slice(nblgt2 - nbtopflop / 2);
            reduceTopFlop = reduceTop.concat(reduceFlop);
            max = reduceTopFlop[0].note;
            min = reduceTopFlop[nbtopflop - 1].note;
          } else {
            reduceTopFlop = topflopSort2;
            max = reduceTopFlop[0].note;
            min = reduceTopFlop[nblgt - 1].note;
            nbtopflop = nblgt2;
          }
          this.chartOptions4.plotOptions.treemap.colorScale.ranges[0].from = min;
          this.chartOptions4.plotOptions.treemap.colorScale.ranges[0].to =
            (min + max) / 2;
          this.chartOptions4.plotOptions.treemap.colorScale.ranges[1].from =
            (min + max) / 2 + 0.1;
          this.chartOptions4.plotOptions.treemap.colorScale.ranges[1].to = max;
          for (let j4 = 0; j4 < nbtopflop; j4++) {
            this.series4[0].data = this.series4[0].data.concat({
              x: "",
              y: null
            });
            this.series4[0].data[j4].x = reduceTopFlop[j4].lgt;
            this.series4[0].data[j4].y = reduceTopFlop[j4].note;
          }
        });
      setTimeout(() => {
        this.refreshKey++;
      }, 1000);
    },
    reLoad() {
      let entityN4All = this.getEntity.filter(el => el.level === 4);
      let nbentityN4All = entityN4All.length;
      for (let e = 0; e < nbentityN4All; e++) {
        let entityN4 = this.getEntity.filter(el => el.level === 4)[e];
        fetch(`
          ${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/data/data.php?bdd=${PORTAL_CONFIG.APP_NAME}&entity=${entityN4.id}&refresh
        `);
        setTimeout(() => {
          this.$router.go(0);
        }, 2000);
      }
    },

    async updateMdp() {
      let pass1Input = "";
      let pass2Input = "";

      const politique = moment()
        .subtract(3, "months")
        //.subtract(30, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      let updateDate = this.getUserData.password_last_update;
      if (!updateDate || updateDate < politique) {
        const { value: formValues } = await Swal.fire({
          title: "Modification de mot de passe",
          icon: "warning",
          showCancelButton: false,
          allowEscapeKey: false,
          customClass: mySwalClass,
          confirmButtonText: "Confirmer",
          allowOutsideClick: false,
          focusConfirm: false,
          html: `En raison de la politique de sécurité il est necessaire de modifier votre mot de passe :
            <input type="password" id="pass1" class="swal2-input" placeholder="Nouveau mot de passe">
            <input type="password" id="pass2" class="swal2-input" placeholder="Confirmer le nouveau mdp">
          `,
          didOpen: () => {
            const popup = Swal.getPopup();
            pass1Input = popup.querySelector("#pass1");
            pass2Input = popup.querySelector("#pass2");
            pass1Input.onkeyup = event =>
              event.key === "Enter" && Swal.clickConfirm();
            pass2Input.onkeyup = event =>
              event.key === "Enter" && Swal.clickConfirm();
          },
          preConfirm: () => {
            const pass1 = document.getElementById("pass1").value;
            const pass2 = document.getElementById("pass2").value;
            if (pass1.length < 3) {
              Swal.showValidationMessage(
                `Le mot de passe doit posséder plus de 3 caractères`
              );
            } else if (pass1 !== pass2) {
              Swal.showValidationMessage(
                `Les mots de passe ne correspondent pas`
              );
            }
            return { pass1, pass2 };
          }
        });
        try {
          await USER_API.mdpUser(
            this.getUserId,
            formValues["pass1"],
            formValues["pass2"]
          );
        } catch ({ response }) {
          this.errors.push({
            title: "attention",
            msg: response.data,
            snackbar: true
          });
        }
        this.killSession().then(() => {
          this.logout();
        });
      }
    },
    autoCookie() {
      let user = this.getUserData.name;
      let userTk = this.getUserData.api_token;
      let duree = 30; //jours
      var date = new Date();
      date.setTime(date.getTime() + duree * 24 * 60 * 60 * 1000);
      let e = "; expires=" + date.toGMTString();
      document.cookie = "kit[nom]=" + user + e + "; path=/";
      document.cookie = "kit[ut]=" + userTk + e + "; path=/";
    },
    notifUpdate() {
      const endDate = "2024-02-11";
      const startHour = "2024-02-10 11:00:00";
      const message =
        "INFO TECHNIQUE : Une défaillance technique du serveur s'est produite ce matin et est résolue depuis 8h35. Nous restons disponibles toute la journée.";
      let goUrl = 0;
      let connexion = localStorage.getItem("last3Refresh");
      const j = moment().format("YYYY-MM-DD");
      const h = moment().format("HH:mm");
      h >= startHour.slice(11, 16) ? goUrl++ : "";
      j < endDate ? goUrl++ : "";
      connexion <= startHour ? goUrl++ : "";
      const url =
        "https://kit.pilote.immo/api/notification?entitiesId=" +
        this.getEntity[0].id +
        "&appName=" +
        PORTAL_CONFIG.APP_NAME +
        "&message=" +
        message;
      if (goUrl === 3) {
        return fetch(url, {
          headers: {
            "Content-Type": "text/calendar; charset=utf-8"
          }
        });
      } else return false;
    }
    /*,
    loadBars2() {
      fetch(`https://app.pilote.immo/kit/data/data.php`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          let size = result.logements.length;
          for (let i = 0; i < size; i++) {
            this.series5[0].data[i].goals[0].name = "Total";
            this.series5[0].data[i].goals[0].strokeColor = "#775DD0";
            this.series5[0].data[i].goals[0].strokeHeight = 10;
            this.series5[0].data[i].goals[0].strokeWidth = 5;
            this.series5[0].data[i].goals[0].value =
              result.logements[i].Lgt_total;
            this.series5[0].data[i].x = result.logements[i].mois;
            this.series5[0].data[i].y = result.logements[i].Lgt_actifs;
            this.series5[0].name = "Total";
          }
        });
    }*/
  },
  mounted() {
    this.notifUpdate();
    PORTAL_CONFIG.APP_NAME == "demo" ? this.updateMdp() : "";
    this.autoCookie();
    this.getAssets.forEach(el => {
      this.items.push(el);
    });
    if (this.isKpi) {
      this.loadBars1();
      //this.loadBars2();
    }
  },
  watch: {
    getAssets: {
      deep: true,
      handler: function() {
        this.items = [{ ...header }];
        this.getAssets.forEach(el => {
          this.items.push(el);
        });
      }
    }
  }
};
</script>

<style lang="scss">
:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 300px;
}

table th {
  border-left: 1px solid #dddddd;
}
table td {
  border-left: 1px solid #dddddd;
}
th {
  &.border-l-0 {
    border-left: none;
  }

  &.border-r-0 {
    border-right: none;
  }
}
#assetsTable2 {
  thead tr th {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }
  &.list__asset tbody tr:first-child {
    background-color: white;
    color: black;

    td {
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
  tbody tr.asset-level {
    background-color: white;
    color: black;
    font-weight: 500;
  }

  tr:nth-of-type(2n) {
    background-color: #f5f5f5 !important;
  }
}
</style>
