<template>
  <v-card>
    <template v-if="show">
      <template v-if="isEsrf"><esrf-assets> </esrf-assets></template>
      <template v-else-if="isLgts"><lgts-assets> </lgts-assets></template>
      <template v-else-if="isBats"
        ><batiment-assets> </batiment-assets
      ></template>
      <template v-else-if="isLocs"
        ><location-assets> </location-assets
      ></template>
      <template v-else>
        <assets-default> </assets-default>
      </template>
    </template>
    <template v-else>
      <map-radius :list="locationList"></map-radius>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import assetsDefault from "./AssetDefault";
import EsrfAssets from "../clients/EsrfAsset";
import LgtsAssets from "../clients/LgtsAsset";
import BatimentAssets from "../clients/BatimentAsset";
import LocationAssets from "../clients/LocationAsset";
import MapRadius from "../components/MapRadius";
import { PORTAL_CONFIG } from "../config";

export default {
  name: "assets",
  components: {
    assetsDefault,
    EsrfAssets,
    LgtsAssets,
    BatimentAssets,
    LocationAssets,
    MapRadius
  },
  data() {
    return {
      locationList: []
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["isAdminUser", "getUserId", "getUserProfiles"]),
    isEsrf() {
      return this.$route.params.assetID === 79;
    },
    isLocs() {
      return this.getUserProfiles[0] === 36; //profil 36 kit_locations
    },
    isLgts() {
      return this.getUserProfiles.includes(32); //profil 32 kit_logement
    },
    isBats() {
      return this.getUserProfiles.includes(35); //profil 35 kit_batiment
    },

    show() {
      return !this.isAdminUser || this.$route.params.assetID;
    }
  },
  methods: {
    loadData() {
      if (this.getUserProfiles.includes(17)) {
        fetch(`${PORTAL_CONFIG.SERVER_WEB}/dev/where.php`)
          .then(response => {
            return response.json();
          })
          .then(result => {
            this.locationList = result;
          });
      }
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  }
};
</script>
